import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

import SEO from 'components/SEO';
import Link from 'components/Link';
import MainHero from 'components/MainHero';
import SideBox from 'components/videoproc/SideBox';
import Divider from 'components/Divider';
// import TextCTA from 'components/videoproc/TextCTA';
import OrderedListSection from 'components/OrderedListSection';
import { CURRENT_YEAR } from 'config';

export default function ConverterPage() {
  const { t } = useTranslation();

  return (
    <>
      <SEO
        title={t('converter:meta.title', {
          info: `[${t('common:updated_year', { year: CURRENT_YEAR })}]`,
        })}
        description={t('converter:meta.desc')}
        keywords={t('converter:meta.keywords')}
      />

      <MainHero
        title={
          <Trans
            i18nKey="common:online_conv"
            components={[<span className="text-blue-500" />]}
          />
        }
        description={
          <Trans
            i18nKey="converter:main_desc"
            components={[<Link href="/sites" className="underline" />]}
          />
        }
        aside={<SideBox />}
      />

      {/* <TextCTA /> */}

      <Divider />

      <OrderedListSection
        title={t('converter:how.title')}
        subtitle={t('converter:how.desc')}
        list={[
          t('converter:how.step1'),
          t('converter:how.step2'),
          <Trans
            i18nKey="converter:how.step3"
            values={{ button: t('common:start') }}
            components={[<strong className="font-bold" />]}
          />,
          t('converter:how.step4'),
        ]}
      />
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "converter", "videoproc"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
