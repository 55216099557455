import React from 'react';

export default function OrderedListSection({ title, subtitle, list }) {
  return (
    <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div className="text-center">
        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl sm:tracking-tight lg:text-5xl">
          {title}
        </h2>
        {subtitle && (
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
            {subtitle}
          </p>
        )}
      </div>

      <div className="prose lg:prose-xl mx-auto">
        <ol>
          {list.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ol>
      </div>
    </div>
  );
}
