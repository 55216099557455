import React from 'react';
import { CheckIcon } from '@heroicons/react/solid';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Button from 'components/Button';
// import { isBrowser } from 'lib/devices';
// import { downloadLink } from 'lib/videoproc';
import { trackPromotion } from 'lib/analytics';
import { getAppLink } from 'lib/videoproc';
import useIsClient from 'hooks/useIsClient';

export default function AppDownload({ title, list, footer, btnText }) {
  const { t, i18n } = useTranslation();

  const isRTL = i18n.dir() === 'rtl';

  const isClient = useIsClient();

  return (
    <div className="bg-gray-50 border border-gray-200 sm:max-w-md sm:w-full sm:mx-auto sm:overflow-hidden rounded-lg shadow">
      {/* <div className="px-4 py-8 sm:px-10"> */}
      <div className="p-4 sm:px-6">
        <div>
          <h4>
            <span className="mt-1 block text-xl tracking-tight font-bold">
              <span className="block text-gray-700">{title}</span>
            </span>
          </h4>
        </div>

        <div className="my-4">
          <div aria-hidden="true">
            <div className="w-full border-t border-gray-300"></div>
          </div>
        </div>

        <div className="flow-root">
          <ul className="-mb-8 pb-3">
            {list.map((content, index) => (
              <li key={index}>
                <div className="relative pb-3">
                  {index !== list.length - 1 && (
                    <span
                      className={`absolute top-4 h-full w-0.5 bg-gray-200 ${
                        isRTL ? 'right-3 -mr-px' : 'left-3 -ml-px'
                      }`}
                      aria-hidden="true"
                    />
                  )}
                  <div
                    className={`relative flex space-x-3 ${
                      isRTL ? 'space-x-reverse' : ''
                    }`}
                  >
                    <div>
                      <span className="h-6 w-6 rounded-full bg-green-500 flex items-center justify-center ring-8 ring-gray-50">
                        <CheckIcon
                          className="h-4 w-4 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <div className="min-w-0 pt-0.5">
                      <div>
                        <p className="text-sm text-gray-600">{content}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <div>
            {/* {isBrowser ? (
              <Button
                as="a"
                href={downloadLink}
                rel="nofollow"
                color="secondary"
                size="sm"
                className="w-full mt-6"
                onClick={() => trackPromotion('btn-dl')}
              >
                {t('videoproc:dl')}
              </Button>
            ) : (
              <Button color="secondary" size="sm" className="w-full mt-6">
                {t('videoproc:dl')}
              </Button>
            )} */}
            <Button
              key={`${isClient}-1`}
              as="a"
              href={getAppLink(i18n.language, 'convert')}
              target="_blank"
              rel="nofollow noreferrer noopener"
              color="secondary"
              size="sm"
              className="w-full mt-6"
              onClick={() => trackPromotion('btn-dl')}
            >
              {btnText ? btnText : t('videoproc:dl')}
            </Button>
          </div>
        </div>
      </div>

      {footer && (
        <div className="px-4 py-6 bg-gray-100 border-t-2 border-gray-200 sm:px-10">
          <p className="text-sm leading-5 text-gray-500">{footer}</p>
        </div>
      )}
    </div>
  );
}
