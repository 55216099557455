import React from 'react';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

// import { trackPromotion } from 'lib/analytics';
// import Link from 'components/Link';
import AppDownload from 'components/videoproc/AppDownload';

export default function SideBox() {
  const { t } = useTranslation();

  return (
    <AppDownload
      title={
        <Trans
          i18nKey="videoproc:video_dl_app"
          components={[<span className="text-blue-500" />, <br />]}
        />
      }
      list={[
        <Trans
          i18nKey="videoproc:save_original_q"
          components={[<strong className="font-bold" />]}
        />,
        <Trans
          i18nKey="videoproc:sb1_2"
          components={[<strong className="font-bold" />]}
        />,
        <Trans
          i18nKey="videoproc:conv_edit_record"
          components={[<strong className="font-bold" />]}
        />,
      ]}
      btnText={t('videoproc:dl2')}
      // footer={
      //   <span className="text-gray-600">
      //     <Trans
      //       i18nKey="videoproc:aside_footer"
      //       components={[
      //         <Link
      //           href="/videoproc"
      //           rel="nofollow"
      //           className="font-bold text-gray-900 underline"
      //           onClick={() => trackPromotion('txt-heres-why')}
      //         />,
      //       ]}
      //     />
      //   </span>
      // }
    />
  );
}
